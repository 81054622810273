<template>
  <router-view/>
</template>

<script>
import myAccountAsyncStore from "./store/myAccountAsyncStore";

export default {
  name: "ControlPanel",
  setup() {
    myAccountAsyncStore.dispatch("checkLogin").then((_) => {
      myAccountAsyncStore.dispatch("getMyData");
    });
  }
}
</script>

<style>
@font-face {
  font-family: 'PNU';
  src: url('assets/fonts/pnu/PNU-Regular.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'PNU';
  src: url('assets/fonts/pnu/PNU-Bold.ttf');
  font-weight: bold;
}


html,body{
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  direction: rtl;
  font-family: 'PNU';
  line-height: 19px;
  min-height: 750px;
  height: 100%;
}

input,select,textarea,option{
  font-family: 'PNU';
}

a{
  text-decoration: none;
}
#app {
  font-family: 'PNU';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
}

.mainContainer{
  display: flex;
  width: 60%;
  padding:0 20%;
  flex-direction: column;
}


@media only screen and (max-width: 600px) {
  .mainContainer{
    width: 92%;
    padding: 0px 4%;
  }
}
</style>
