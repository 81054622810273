<template>
  <div class="iconWorker" :style="getStyle()">
    <img :src="require('@/assets/loading.gif')" v-if="icon === 'loading'">
  </div>
</template>

<script>
export default {
  name: "IconsWorker",
  props: {
    width: {
      type: Number,
      default: 25
    },
    color: {
      type: String,
      default: "#000"
    },
    icon: {
      type: String,
      default: "close"
    },
  },
  setup(props) {
    const getStyle = () => {
      let _icon = props.icon;
      if(_icon === "next") {
        _icon = "rightRowF";
      } else if(_icon === "back") {
        _icon = "leftRowF";
      }

      const _style = {
        width: `${props.width}px`,
        height: `${props.width}px`,
      };

      if(_icon !== "loading") {
        _style['-webkit-mask'] = 'url(' + require('@/assets/icons/' + _icon + '.svg') +') no-repeat center';
        _style['mask'] = 'url(' + require('@/assets/icons/' + _icon + '.svg') +') no-repeat center';
        _style.backgroundColor = `${props.color}`;
      }
      return _style;
    }
    return {
      getStyle,
    };
  }
}
</script>

<style scoped>
  .iconWorker{
    width: 80px;
    height: 80px;
    display: flex;
    /* -webkit-mask: url(../assets/icons/facebookF.svg) no-repeat center;
    mask: url(../assets/icons/facebookF.svg) no-repeat center;*/
  }
  .iconWorker img{
    width: 100%;
  }
</style>