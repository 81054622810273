<template>
  <div class="uploaderContainer">
    <div class="lable">{{label}}</div>
    <IconsWorker icon="loading" :width="20" class="fetchingOldDataLoadingIcon" v-if="state.isFetchingFiles"/>
    <div class="uploadSection" :style="getMainStyle()" v-else>
      <button :class="['placeholder', isAllowedToShowUploadButton() ? '' : 'disableUploadButton']" :id="selectFileId">
        <IconsWorker icon="circuleAddF" :width="20" color="#555"/>
        <span>{{placeHolder}}</span>
      </button>
      <div class="itemsContainer">
        <!--<div class="cell">
          <div class="thumb"></div>
          <div class="infoSide">
            <div class="name">fdklfkd</div>
            <div class="size">fdklfkd</div>
          </div>
          <div class="uploadState">
            <IconsWorker icon="errorSpot" :width="17"/>
            <span>فشل الرفع</span>
          </div>
          <div class="removeButton">
            <IconsWorker icon="delete" :width="20"/>
          </div>
        </div>-->

        <div class="cell" v-for="(file, index) in state.files" :key="'uploadCel' + index">
            <div class="thumb" :style="{backgroundImage: `url(${file.thumb === null? require('@/assets/icons/file.svg')  : file.thumb})`}"></div>
            <div class="infoSide">
              <div class="name">{{file.serial}}</div>
              <div class="size" v-if="file.state !== 'uploading'">{{ file.size }}</div>
              <div class="progressBar" v-if="file.state === 'uploading'">
                <div class="current" :style="{width: file.percent + '%'}"></div>
              </div>
            </div>
            <div class="uploadState">
              <IconsWorker :icon="getFileStateInfo(file).icon" :width="17" :color="getFileStateInfo(file).color"/>
              <span>{{getFileStateInfo(file).text}}</span>
            </div>
            <div class="removeButton" @click="removeFile(file)">
              <IconsWorker icon="delete" :width="20"/>
            </div>
          </div>
        </div>
      <div class="errorsHandler" v-if="state.errorMessage !== ''">{{state.errorMessage}}</div>
    </div>
  </div>
</template>

<script>
import {getCurrentInstance, onMounted, reactive, watch} from 'vue';
import IconsWorker from "./IconsWorker";
import plupload from 'plupload';
import AjaxWorker from "../helpers/AjaxWorker";
import FixedNotification from "./plugins/fixedNotifications/FixedNotification";

export default {
  name: "DuInputUploader",
  components: {IconsWorker},
  props: {
    placeHolder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    fileBrowseTitle: {
      type: String,
      default: 'files'
    },
    allowedExtensions: {
      type: String,
      default: 'jpg,gif,png,jpeg,JPG,GIF,PNG'
    },
    url: {
      type: String,
      default: '/transactions.php?action=uploadImage&uploadImage=postImage'
    },
    isMultiSelections: {
      type: Boolean,
      default: true
    },
    isRequiredMessage: {
      type: String,
      default: ''
    },
    filesChanged: {
      type: Function,
      default: () => {},
    },
    getRef: {
      type: Function,
      default: () => {},
    },
    waitToUploadAllQueue: {
      type: String,
      default: "لم يكتمل رفع جميل الملفات"
    },
    initFiles: {
      type: Array,
      default: []
    },
    maxFilesCount: {
      type: Number,
    }
  },
  setup(props, { emit }){
    const state = reactive({
      uploader: null,
      files: [],
      isValid: null,
      errorMessage: '',
      isFetchingFiles: false,
    });
    const selectFileId = `selectFile-${Math.random().toLocaleString(16)}`;
    const validateNow = () => {
      state.isValid = true;
      if(props.isRequiredMessage !== "") {
        if(state.files.length === 0){
          state.isValid = false;
          state.errorMessage = props.isRequiredMessage;
        }
      }

      if(state.isValid) {
        state.files.forEach((element) => {
          if(element.state !== "uploaded") {
            state.isValid = false;
            state.errorMessage = props.waitToUploadAllQueue;
          }
        });
      }

      if(state.isValid) state.errorMessage = '';
    }

    onMounted(() => {
      props.getRef(getCurrentInstance().proxy);
    });

    const initFiles = () => {
      if(props.initFiles.length > 0) {
        props.initFiles.forEach(async (fileSerial) => {
          state.isFetchingFiles = true;
          const _url = new AjaxWorker().url + "/media/"+ fileSerial + "?w=200"
          let blob = await fetch(_url).then(async r => await r.blob());

          let _newFile = {};
          _newFile = {
            serial: fileSerial,
            id: Math.random().toLocaleString(16),
            state: 'uploaded',
            name: blob.size,
            type: blob.type,
          };
          if(blob.type.includes('image')) {
            _newFile.thumb = URL.createObjectURL(blob);
          }
          state.files.push(_newFile);
          state.isFetchingFiles = false;
          /*
          new AjaxWorker().request("/media/" + fileSerial).then((res) => {
            console.log(res);
          });*/
        });
      }
    }

    onMounted(() => {
      state.uploader = new plupload.Uploader({
        runtimes: 'html5,html4',
        browse_button: selectFileId, // you can pass in id...
        unique_names: true,
        file_data_name: 'name',
        multipart: true,
        multi_selection : props.isMultiSelections,
        url: new AjaxWorker().url + props.url,
        thumb: true,
        headers: {
          //id: mainStore.getters.id,
          //authKey: mainStore.getters.authKey,
        },
        filters: {
          mime_types: [
            {title: props.fileBrowseTitle, extensions: props.allowedExtensions},
          ]
        },
        init: {
          FilesAdded: (up, files) => {
            files.forEach((file) => {
              let fileObject = {
                name: file.name,
                size: file.size,
                type: file.type,
                id: file.id,
                percent: 0,
                thumb: null,
                state: 'ready'
              };
              if(file.type.includes('image')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  fileObject.thumb = URL.createObjectURL(file.getNative());
                  state.files.push(fileObject);
                  onInput();
                  up.start();
                };
                reader.readAsDataURL(file.getNative());
              } else {
                state.files.push(fileObject);
                onInput();
                up.start();
              }
            });
          },
          FileUploaded: (up, file, info) => {
            if(info.response === "NOINTERNETCONNECTION") {
              FixedNotification.create({
                title: 'مشكلة في الإتصال بالخادم',
                decription: 'حاول مجددًا  ',
                icon: 'errorF',
                color: 'red',
                hideAfter: 3000
              });
            } else {
              let response = JSON.parse(info.response);
              if(response.state) {
                state.files.forEach((element, index) => {
                  if (element.id === file.id) {
                    state.files[index].state = 'uploaded';
                    state.files[index].serial = response.serial;
                  }
                });
              } else {
                FixedNotification.create({
                  title: 'خطاء غير معلوم',
                  decription: 'حاول مرة أخرى',
                  icon: 'errorF',
                  color: 'red',
                  hideAfter: 3000
                });
              }
            }
            onInput();
          },
          UploadProgress: (up, file) => {
            state.files.forEach((element, index) => {
              if(element.id === file.id) {
                state.files[index].state = 'uploading';
                state.files[index].percent = file.percent;
              }
            });
          },
          Error: (up, err) => {
            alert("خطاء في رفع الصورة");
            onInput();
          },

        }
      });
      state.uploader.init();
      setTimeout(() => {
        initFiles();
      }, 1000);
    });


    const onInput = () => {
      if(state.isValid != null) validateNow();
      props.filesChanged({
        isValid: state.isValid,
        value: state.files,
      });
    }

    const getFileStateInfo = (file) => {
      const _state = {
        text: "...",
        icon: 'updateF',
        color: '#000'
      };
      if(file.state === 'ready') {
        _state.text = 'بإنتظار الرفع';
        _state.icon = 'updateF';
        _state.color = '#000';
      } else if(file.state === 'uploading') {
        _state.text = 'جار الرفع';
        _state.icon = 'updateF';
        _state.color = '#000';
      } else if(file.state === 'ready') {
        _state.text = '';
        _state.icon = 'updateF';
        _state.color = '#000';
      } else if(file.state === 'uploaded') {
        _state.text = '';
        _state.icon = 'cloudTrueF';
        _state.color = 'green';
      }
      return _state;
    }


    const getMainStyle = () => {
      if(state.isValid === false) {
        return {
          background: 'rgba(255, 0, 83, 0.1)',
        }
      }

      return {};
    }

    const removeFile = (file) => {
      const _id = file.id;
      state.files.forEach((element, index) => {
        if(element.id === file.id) {
          state.files.splice(index, 1);
        }
      });
    }


    const isAllowedToShowUploadButton = () => {
      if(state.uploader != null) {
        if (props.maxFilesCount !== undefined) {
          if (state.files.length >= props.maxFilesCount) {
            state.uploader.disableBrowse(true);
            return false;
          }
        }
        //disableBrowse()
        state.uploader.disableBrowse(false);
        return true;
      }
    }
    return {
      removeFile,
      getMainStyle,
      isAllowedToShowUploadButton,
      selectFileId,
      getFileStateInfo,
      validateNow,
      state
    };
  }
}
</script>

<style scoped>
.uploaderContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.DuInputMainContainer .label{
  font-size: 17px;
  /* color: #555; */
  margin-bottom: 10px;
}
.uploadSection{
  width: 90%;
  padding: 10px 5%;
  border-radius: 10px;
  background-color: #eee;
  margin-top: 10px;
}
.uploadSection .placeholder{
  cursor: pointer;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0;
  background: transparent;
}
.uploadSection .placeholder:hover{ opacity: 0.5; }

.itemsContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-bottom: 10px;
}

.itemsContainer .cell{
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  display: flex;
  flex-direction: row;
  column-gap: 10px;

}
.itemsContainer .cell .thumb{
  height: 80px;
  width: 80px;
  background-color: #ccc;
  background-size: cover;
  background-position: center;
}
.itemsContainer .cell .infoSide{
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  justify-items: center;
  justify-content: center;
}
.itemsContainer .cell .infoSide .name{
  font-size: 14px;
  color: #111;
}
.itemsContainer .cell .infoSide .size{
  font-size: 12px;
  color: #555;
  margin-top: 5px;
  max-width: available;
}
.removeButton{
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin: 0 20px;
  cursor: pointer;
}
.uploadState{
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;
  row-gap: 5px;
  font-size: 12px;
  font-weight: bold;
}

.progressBar{
  width: 100%;
  height: 5px;
  border-radius: 100px;
  margin-top: 5px;
  background: #eee;
}
.progressBar .current{
  width: 50%;
  height: 100%;
  border-radius: 100px;
  background: green;
}

.errorsHandler{
  width: 90%;
  padding: 10px 5%;
  background: rgba(255, 0, 83, 0.5);
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}

.disableUploadButton{
  pointer-events: none;
  opacity: 0.2;
}

.fetchingOldDataLoadingIcon{
  place-self: center;
}
</style>