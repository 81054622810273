import FixedNotificationComponent from "./FixedNotificationComponent.vue";
import {createApp} from "vue";

const FixedNotification = new class FixedNotification{
    create = function ({
           title = "",
           decription = "",
           icon,
           onClick = (() => {}),
           hideAfter = 2000,
           color= "#000"
       }) {
        if(!document.getElementById("notificationsRoot")) {
            const comp = FixedNotificationComponent;
            const rootElement = document.createElement('div');
            rootElement.setAttribute("id", "notificationsRoot");
            document.body.appendChild(rootElement);
            createApp(comp).mount(rootElement);
        }
        const creationEvent = new CustomEvent('createNotification');

        creationEvent.data = arguments[0];
        document.dispatchEvent(creationEvent);
    }
}

export default FixedNotification;