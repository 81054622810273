import Axios from 'axios';
// eslint-disable-next-line import/no-cycle
import myAccountAsyncStore from "../store/myAccountAsyncStore";

export default class AjaxWorker {

  //url = 'http://localhost/photostawazon/api';
  url = 'https://loc.dpmp.sa/api';

  async request(url, params = {}) {
    let response = 'NOINTERNETCONNECTION';
    const headers = {};

    if (myAccountAsyncStore.state.isLogin) {
      const credentials = myAccountAsyncStore.getters.getCredentials;
      headers.id = credentials.id;
      headers.authKey = credentials.authKey;
    }

    await Axios.request({
      url: `${this.url}${url}`,
      params,
      headers,
      method: params.length > 0 ? 'POST' : 'GET',
    }).then((connectionResponse) => {
      response = connectionResponse.data;
    }).catch((e) => {
        window.console.log('connection error');
        window.console.log(e);
    });
    return response;
  }
}
