import { createApp } from 'vue';
import duContextMenuComponent from './duContextMenuComponent.vue';
import FixedNotificationComponent from "../fixedNotifications/FixedNotificationComponent";

const DuContextMenu = new class{
  create = function({
    event = null,
    onSelect= (() => {}),
    options= [],
  }) {
    const params = arguments[0];
    params.id = Math.floor(Math.random() * 1000000) + 1;

    if(!document.getElementById("createContextMenu")) {
      const comp = duContextMenuComponent;
      const rootElement = document.createElement('div');
      rootElement.setAttribute("id", "createContextMenu");
      document.body.appendChild(rootElement);
      createApp(comp).mount(rootElement);
    }

    const creationEvent = new CustomEvent('createContextMenu');
    creationEvent.data = params;
    document.dispatchEvent(creationEvent);
  }
};

export default DuContextMenu;
