<template>
  <div>
    <div class="theContextMenuLightBox"
         v-for="item in state.dialogsStack" :key="item.id" id="parentMenu">
      <div class="ContextMenuBox" :style="getBoxStyle()">
          <div class="cell" v-for="option in item.options" :key="option.key"
               @click="item.onSelect(option.key); closeDialog()">
            <IconsWorker :icon="option.icon" :width="22"/>
            <div style="width: 20px;height: 10px;"></div>
            <div class="theTitle">{{ option.title }}</div>
          </div>
      </div>
      <div class="cell mobileClose" @click="closeDialog()">إغلاق</div>
    </div>
  </div>
</template>

<script>
import { watch, reactive } from 'vue';
import IconsWorker from "../../IconsWorker";

export default {
  name: 'duContextMenuComponent',
  components: {IconsWorker},
  setup() {
    const state = reactive({
      dialogsStack: [],
      xMousePosition: 0,
      yMousePosition: 0,
    });
    const blurBack = () => {
      document.getElementById('app').style.filter = `blur(${(state.dialogsStack.length) * 3}px)`;
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    };
    const unBlurBack = () => {
      document.getElementById('app').style.filter = 'blur(0px)';
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
    const closeDialog = () => {
      state.dialogsStack = [];
    };
    const isDescendant = (el, parentId) => {
      let isChild = false;
      if (el.id === parentId) {
        isChild = true;
      }
      // eslint-disable-next-line no-cond-assign,no-param-reassign
      while (el === el.parentNode) {
        if (el.id === parentId) {
          isChild = true;
        }
      }
      return isChild;
    };
    const outSideClickListener = (e) => {
      // في حال المستخدم ضغط برا القائمة
      if (isDescendant(e.target, 'parentMenu')) {
        closeDialog();
      }
    };
    const dialogKeyListener = (e) => {
      if (e.keyCode === 27) {
        if (state.dialogsStack[state.dialogsStack.length - 1].onCancel !== undefined) {
          state.dialogsStack[state.dialogsStack.length - 1].onCancel();
        }
        closeDialog();
      }
    };
    document.addEventListener('createContextMenu', (e) => {
      state.xMousePosition = e.data.event.clientX;
      state.yMousePosition = e.data.event.clientY;
      state.dialogsStack.push(e.data);
    });
    watch(() => state.dialogsStack.length, () => {
      if (state.dialogsStack.length === 0) {
        unBlurBack();
        document.removeEventListener('keydown', dialogKeyListener);
        document.removeEventListener('click', outSideClickListener);
      } else {
        blurBack();
        document.addEventListener('click', outSideClickListener);
        document.addEventListener('keydown', dialogKeyListener);
      }
    });

    const getBoxStyle = () => {
      const screenWidth = window.innerWidth;
      if(screenWidth <= 640) {
        return {
          bottom: '75px',
          left: '0px',
        };
      }
      return {
        left: state.xMousePosition + 'px',
        top: state.yMousePosition + 'px',
      };
    }
    return {
      state,
      getBoxStyle,
      closeDialog,
    };
  },
};
</script>

<style scoped>
  .theContextMenuLightBox{
    display: flex;
    position: fixed;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    opacity: 0.97;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
  .ContextMenuBox{
    background: #fff;
    box-shadow: 0 0 10px #999;
    border-radius: 10px;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
  }
  .cell{
    padding: 15px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    cursor: pointer;
    align-self: stretch;
  }
  .cell:hover{
    background: #eee;
  }
  .cell:active{
    background: #ccc;
  }
  .cell .theTitle{
    min-width: 100px;
    flex: 1;
    font-size: 18px;
    color: #000;
    margin-top: 2px;
  }
  .cell .icon{
    width: 15px;
    height: 15px;
  }

  .mobileClose{
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .ContextMenuBox {
      width: 90%;
      margin: auto 5%;
      position: fixed;
    }
    .mobileClose{
      display: block;
      position: fixed;
      bottom: 10px;
      left: 0;
      padding: 15px 0;
      text-align: center;
      background: #fff;
      border-radius: 10px;
      width: 90%;
      margin: auto 5%;
      box-shadow: 0 0 10px #999;
      color: red;
      font-size: 17px;
      font-weight: bold;
    }
    .mobileClose:hover{opacity: 0.5;}
    .mobileClose:active{background: red; color: #fff; opacity: 0.3;}
  }
</style>
