import { createStore } from 'vuex'
import AjaxWorker from "../helpers/AjaxWorker";

export default createStore({
    state: {
        isLogin: false,
        data: {},
        credentials: {
            id: null,
            authKey: null,
        },
    },
    mutations: {
        updateData(state, newData) {
            state.data = newData;
        },
        updateCredentials(state, newCredentials) {
            state.credentials = newCredentials;
            state.isLogin = newCredentials.isLogin;
        }
    },
    actions: {
        async checkLogin(state) {
            console.log('ss');
            let _data = { id: null, state: null };
            let _credentials = { isLogin: false, id: 0, authKey: null };
            try {
                const dataFromLS = JSON.parse(localStorage.getItem('data'));
                if(dataFromLS !== null) _data = dataFromLS;
                const credentialsFromLS = await JSON.parse(localStorage.getItem('credentials'));
                if(credentialsFromLS !== null) _credentials = {...credentialsFromLS, isLogin: true};
            } catch (e) {}
            await this.commit('updateCredentials', _credentials);
            await this.commit('updateData', _data);

        },
        async getMyData(state) {
            await new AjaxWorker().request('/transactions.php?action=getData').then(async (res) => {
                if(res !== "NOINTERNETCONNECTION") {
                    if(res.state) {
                        this.commit('updateData', res);
                        await localStorage.setItem('data', JSON.stringify(res));
                    } else {
                        localStorage.removeItem("credentials");
                        localStorage.removeItem("data");
                        state.isLogin = false;
                    }
                }
            });
        },
        async login(state, { id, authKey }) {
            await this.commit('updateCredentials', {id, authKey, isLogin: true});
            await localStorage.setItem('credentials', JSON.stringify({id, authKey}));
            this.dispatch('getMyData');
        },
        async logout(state) {
            await localStorage.removeItem('credentials');
            await this.commit('updateCredentials', {id: null, authKey: null, isLogin: false});
            this.commit('updateData', {});
        },
    },
    modules: {
    },
    getters: {
        isLogin(state) {
            return state.isLogin;
        },
        getCredentials(state) {
            return state.credentials;
        },
        getData(state) {
            return state.data;
        }
    }
});

/*
import { createStore } from 'vuex'
import AjaxWorker from "../helpers/AjaxWorker";

export default createStore({
    state: {
        isLogin: false,
        data: {},
        credentials: {
            id: null,
            authKey: null,
        },
    },
    mutations: {
        updateData(state, newData) {
            state.data = newData;
        },
        updateCredentials(state, newCredentials) {
            state.credentials = newCredentials;
            state.isLogin = newCredentials.isLogin;
        }
    },
    actions: {
        async checkLogin(state) {
            console.log('ss');
            let _data = { id: null, state: null };
            let _credentials = { isLogin: false, id: 0, authKey: null };
            try {
                const dataFromLS = await JSON.parse(localStorage.getItem('data'));
                if(dataFromLS !== null) _data = dataFromLS;
                const credentialsFromLS = await JSON.parse(localStorage.getItem('credentials'));
                if(credentialsFromLS !== null) _credentials = {...credentialsFromLS, isLogin: true};
            } catch (e) {}
            this.commit('updateCredentials', _credentials);
            this.commit('updateData', _data);
        },
        async getMyData(state) {
            await new AjaxWorker().request('/transactions.php?action=getData').then((res) => {
                this.commit('updateData', res);
            });
        },
        async login(state, { id, authKey }) {
            await this.commit('updateCredentials', {id, authKey, isLogin: true});
            await localStorage.setItem('credentials', JSON.stringify({id, authKey}));
            this.dispatch('getMyData');
        },
        async logout(state) {
            await localStorage.removeItem('credentials');
            await this.commit('updateCredentials', {id: null, authKey: null, isLogin: false});
            this.commit('updateData', {});
        },
    },
    modules: {
    },
    getters: {
        isLogin(state) {
            return state.isLogin;
        },
        getCredentials(state) {
            return state.credentials;
        },
        getData(state) {
            return state.data;
        }
    }
})

 */