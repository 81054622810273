<template>
  <button class="duButton" :style="getStyle()">{{text}}</button>
</template>

<script>
export default {
  name: "DuButton",
  props: {
    text: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: '#FF0029'
    },
    textColor: {
      type: String,
      default: '#fff'
    },
    fontSize: {
      type: Number,
      default: 16
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props){
    const getStyle = () => {
      const style = { backgroundColor: props.background, color: props.textColor, fontSize: props.fontSize };
      if(props.disabled) {
        style.opacity = '0.5';
        style.cursor = 'auto';
        style["pointer-events"] = 'none';
      }
      return style;
    }

    return {
      getStyle,
    }
  }
}
</script>

<style scoped>
.duButton{
  padding: 10px 20px;
  border-radius: 100px;
  border: 0;
  font-weight: bold;
  box-shadow: 0 0 5px #ccc;
  cursor: pointer;
}
.duButton:hover{
  opacity: 0.8;
}
.duButton:active{
  opacity: 0.5;
}
</style>