<template>
  <div class="fixedNotificationsModel">
    <div class="cell" v-for="notification in state.notifications" :key="notification.id">
      <div class="thumb" v-if="notification.icon" @click="onNotificationClick(notification.id)" :style="{ background: notification.color ? notification.color : '#000' }">
        <IconsWorker :icon="notification.icon" width="30" color="#fff"/>
      </div>
      <div class="infoSide" @click="onNotificationClick(notification.id)">
        <div class="title" v-if="notification.title">{{notification.title}}</div>
        <div class="disc" v-if="notification.decription">{{notification.decription}}</div>
      </div>
      <IconsWorker icon="close" width="20" class="closeNotification" @click="removeNotification(notification.id)"/>
      <div class="gap"></div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import IconsWorker from "../../IconsWorker";
export default {
  name: "FixedNotificationComponent",
  components: {IconsWorker},
  setup() {
    const state = reactive({
      notifications: []
    });
    document.addEventListener('createNotification', (e) => {
      let data = e.data;
      data.id = Math.random().toLocaleString(16);
      state.notifications.push(data);

      if(data.hideAfter) {
        setTimeout(() => {
          state.notifications = state.notifications.filter((e) => e.id !== data.id);
        }, data.hideAfter);
      }
    });
    const removeNotification = (notificationId) => {
      state.notifications = state.notifications.filter((e) => e.id !== notificationId);
    }
    const onNotificationClick = (notificationId) => {
      state.notifications.forEach((element, index) => {
        if(element.id === notificationId) {
          if(element.onClick) {
            element.onClick();
          }
        }
      });
    }
    return {
      state,
      removeNotification,
      onNotificationClick,
    };
  },
}
</script>

<style scoped>
  .fixedNotificationsModel{
    width: auto;
    position: fixed;
    display: flex;
    flex-direction: column;
    top:0;
    alignment: left;
    row-gap: 20px;
    margin: 50px;
    z-index: 120;
  }

  .fixedNotificationsModel .cell{
    width: 380px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px #ccc;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    opacity: 0.97;
  }
  .fixedNotificationsModel .cell:hover{
    opacity: 1;
  }

  .fixedNotificationsModel .cell .infoSide{
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
    cursor: pointer;
  }
  .fixedNotificationsModel .cell .infoSide .title{
    font-size: 17px;
  }
  .fixedNotificationsModel .cell .infoSide .disc{
    font-size: 15px;
    margin-top: 10px;
  }
  .closeNotification{
    padding: 5px;
    cursor: pointer;
  }
  .thumb{
    background: #555;
    place-self: stretch;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }


  @media only screen and (max-width: 600px) {
    .fixedNotificationsModel{
      top:0;
      left: 0;
      margin: 20px 5%;
      width: 90%;
    }
    .fixedNotificationsModel .cell{
      width: 100%;
    }
    .fixedNotificationsModel .cell .infoSide .title{
      font-size: 16px;
    }
    .fixedNotificationsModel .cell .infoSide .disc {
      font-size: 14px;
    }
    .closeNotification{
      padding: 10px;
    }
  }
</style>