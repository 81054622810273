<template>
  <div class="home">
    <div class="fixedLogo"></div>
    <div class="mainContainer">
      <TopMenu />
      <img src="../assets/images/logo.png" class="centerLogo"/>
      <div class="selectArea">
          <div class="interface">
          <select class="areaLable" @change="(e) => openAreaBox(e)" placeholder="المنطقة" >
            <option class="cell" @click="goToAllPlaces()">
              كل المناطق
            </option>
            <option class="cell" v-for="(area, index) in state.listOfAreas" :key="index" @click="selectArea(area.id)">
              {{area.title}}
            </option>
          </select>
          <span class="searchBox" :style="{ border: state.isKeyWordEmpty? '1px solid red' : ''}">
            <form action="#" @submit="onSearchSubmit"><input placeholder="البحث عن مكان" v-model="state.searchQueryKeyWord" /></form>
            <IconsWorker icon="searchF" color="black" :width="25" class="searchIcon" @click="onSearchSubmit()"/>
          </span>
        </div>
      </div>
      <div class="siteDescription">
        منصة loc.dp تحتوي على مواقع أماكن تساعد المصورين والهواة على زيارتها والتقاط الصور والفيديو مزودة بجميع معلومات الوصول
      </div>
      <div class="siteRights">
        جميع الحقوق محفوظة لدى دي بي للانتاج الاعلامي . © 2021
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import TopMenu from "../components/TopMenu";
import Footer from "../components/Footer";
import IconsWorker from "../components/IconsWorker";
import {onMounted, reactive} from "vue";
import router from "../router";
import BrowserHelper from "../helpers/BrowserHelper";
import AjaxWorker from "../helpers/AjaxWorker";

export default {
  name: 'Home',
  components: {
    IconsWorker,
    Footer,
    TopMenu,
  },
  setup() {
    const state = reactive({
      isAreasBoxOpen: false,
      isKeyWordEmpty: false,
      selectedArea: '',
      searchQueryKeyWord: '',
      areas: [
          'مكة',
          'المدينة'
      ],
      isLoadingAreas: true,
      listOfAreas: true,
    });
    const openAreaBox = (e) => {
      state.isAreasBoxOpen = !state.isAreasBoxOpen;
    }

    const selectArea = (area) => {
      state.selectedArea = area;
    }

    const getAreas = () => {
      new AjaxWorker().request('/transactions.php?action=getCats&getAll=true&type=area').then((res) => {
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          setTimeout(() => {
            getAreas();
          },2000);
        } else {
          state.isLoadingAreas = false;
          state.listOfAreas = res.data;
        }
      });
    }

    onMounted(() => {
      state.isKeyWordEmpty = false;
      BrowserHelper.changeTitle('الرئيسية');
      getAreas();
    });
    const onSearchSubmit = async (e) => {
      let area = state.selectedArea;
      if(state.searchQueryKeyWord === '') {
        state.isKeyWordEmpty = true;

        /*
        if(area === '')
          area = 'all';
        router.push('/categories/city/' + area);

         */
      } else {
        state.isKeyWordEmpty = false;
        let link = '/places?placeNamesQuery=' + state.searchQueryKeyWord;
        if(area !== '')
          link = link + '&area=' + area;
        router.push(link);
      }
      if(e !== undefined) {
        e.preventDefault();
      }
      return false;
    }
    return {
      selectArea,
      openAreaBox,
      onSearchSubmit,
      state,
    };
  }
}
</script>

<style>
  .home{
    background-color: #000;
    width: 100%;
    background-image: url("../assets/images/home_bg.jpg");
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    min-height: 100%;
  }

  .centerLogo{
    width: 200px;
    place-self: center;
  }

  .selectArea{
    width: 90%;
    margin: 10px 5%;
    background: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
  }
  .selectArea .interface{
    width: 100%;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    height: 55px;
    align-items: center;
    cursor: pointer;
  }

  .selectArea .areaLable{
    font-size: 17px;
    font-weight: bold;
    color: #555;
    padding-inline-start: 20px;
    padding-top: 0px;
    border: 0;
    text-indent: 1px;
    text-overflow: '';
  }


  .selectArea .areaLable .cell{
    display: flex;
    flex-direction: row;
    font-size: 17px;
    font-weight: bold;
    width: 90%;
    padding: 20px 5%;
  }


  .selectArea .searchBox{
    font-size: 18px;
    font-weight: bold;
    color: #555;
    flex: 1;
    border: 1px solid #ccc;
    margin: 5px 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    height: 35px;
    align-items: center;
    background: #eee;
  }



  .selectArea .searchBox form{
    flex: 1;
    display: flex;
    flex-direction: row;
    width: available;
  }
  .selectArea .searchBox input{
    padding: 2px 10px;
    border: 0;
    flex: 1;
    font-size: 18px;
    background: transparent;
  }

  .selectArea .searchBox input:focus {outline:0;}
  .searchIcon{
    cursor: pointer;
    opacity: 0.6;
  }
  .searchIcon:hover{ opacity: 1; }

  .iconOfRow{
    margin: 10px 10px;
  }

  .siteDescription{
    padding: 3px 5px;
    max-width: 90%;
    width: max-content;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    border-radius: 100px;
    place-self: center;
    margin-top: 50px;
  }
  .siteRights{
    padding: 1px 5px;
    max-width: 90%;
    width: max-content;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    border-radius: 100px;
    place-self: center;
    margin-top: 150px;
  }

  .fixedLogo{
    width: 200px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    margin: -10px 100px;
    background-image: url("../assets/images/fixedLogo.png");
    background-size: auto 100%;
    background-position: center;
  }

  @media only screen and (max-width: 600px) {
    .home{
      background-attachment: scroll;
    }
    .fixedLogo{
      display: none;
    }
    .selectArea {
      width: 100%;
      margin: 10px 0%;
    }
    .centerLogo{
      width: 100px;
      place-self: center;
    }
    .selectArea .areaLable{
      font-size: 14px;
    }
    .selectArea .searchBox{
      font-size: 15px;
    }
    .iconOfRow{
      margin: 0;
    }
    .selectArea .searchBox input{
      width: 100px;
    }
  }
</style>
