<template>
  <div class="duInputLightBox" v-show="state.isMenuLightBoxIsOpen" @click="closeSelectMenu()"></div>
  <div class="DuInputMainContainer" ref="mainContainer">
    <div
        class="label"
        :style="{ color: labelColor }">
      {{ label }}
    </div>
    <div class="inputSection" :style="getInputSectionStyle()" :class="state.isValid !== null && !state.isValid ? 'errorBorder' : '' ">
      <div v-if="type === 'selectMenu'" class='selectMenu'>
        <div class="selectMenuPlaceHolder"
             @click="(e) => openSelectMenu(e)">{{ getSelectMenuInterfaceText() }}</div>
        <div class="selectMenuBody"
             id="selectMenuBodyId"
             v-if="state.isMenuLightBoxIsOpen"
             :style="getSelectMenuBoxStyle()">
          <div class="theLightBoxTitle">{{ placeholder }}</div>
          <div class="theContentArea">
            <div class="cell" v-for="item in options" :key="item.text"
                 @click="selectMenuItem(item)">
              <IconsWorker :width="16" :icon="item.icon" v-if="item.icon !== undefined"/>
              <div style="width: 20px;height: 20px;"  v-if="item.icon !== undefined"></div>
              <div class="theText">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="toggleButtonHolder" v-else-if="type === 'toggle'">
        <div class="toggleButton" @click="changeToggle()">
          <div class="switcher" :style="getToggleButtonStyle()"></div>
        </div>
        <div class="switcherPadding"></div>
      </div>
      <div v-else-if="type === 'multiSelectList'" class="multiSelect">
        <div class="multiSelectCel" v-for="item in options" :key="item.text + 'dddd'" @click="selectMultiSelectItem(item)" :style="getMultiSelectItemCelStyle(item)">
          <div class="checkBox">
            <IconsWorker icon="trueF" color="#000" :width="20" v-if="checkIfItemListSelected(item)"/>
          </div>
          <div class="titleOfItem">{{ item.text }}</div>
        </div>
      </div>
      <textarea v-else-if="type === 'textarea'" v-model="state.innerValue"
                :ref="inputRef"
                @focusout="onFocusOut"
                @input="onInputHandle"
                @keydown="onkeydown"
                :placeholder="placeholder"
      ></textarea>
      <div class="tagsHolder" v-else-if="type === 'tags'" @click="focusOnTagsInput">
        <span class="tagCell" v-for="(tag, index) in getTagsAsArray()">
          <div class="removeButton" @click="removeTag(index)"><IconsWorker icon="closeF" :width="15" color="#fff"/></div>
          <span>{{ tag }}</span>
        </span>
        <input v-model="state.newTagValue"
               ref="inputRef"
               @focusout="onFocusOut"
               @keydown="onkeydown"
               :placeholder="placeholder"
               :size="state.newTagValue.length"
               @keyup.enter="onSubmitTag"
        />
      </div>
      <input v-model="state.innerValue"
             :ref="inputRef"
             @focusout="onFocusOut"
             @input="onInputHandle"
             @keydown="onkeydown"
             :placeholder="placeholder"
             :type="getTextFildType()"
             v-else
      />
      <div v-if="type === 'selectMenu'" class="selectMenuMark" @click="openSelectMenu()">
        <IconsWorker icon="bottomRowF" width="20"/>
        <div style="width: 15px;height: 15px;"></div>
      </div>
      <div class="errorSpot" v-if="state.isValid !== null && !state.isValid">
        <IconsWorker icon="errorSpot" color="red" width="20"/>
      </div>
    </div>
    <div class="errorHandler" v-if="state.isValid !== null && !state.isValid">
      {{ state.errorMessage }}
    </div>
  </div>
</template>

<script>
import {
  reactive, ref, onMounted, watch, getCurrentInstance
} from 'vue';
import IconsWorker from "./IconsWorker";
export default {
  name: 'DuInput',
  components: {IconsWorker},
  setup(props, { emit }) {
    const mainContainer = ref("mainContainer");
    const inputRef = ref();
    const state = reactive({
      isFocusBefore: false,
      innerValue: props.value !== undefined ? props.value : '',
      errorMessage: '',
      isValid: true,
      mainContainerWidth: 0,
      isMenuLightBoxIsOpen: false,
      name: props.name,
      newTagValue: '',
    });
    onMounted(() => {
      state.mainContainerWidth = mainContainer.value.clientWidth;
      props.getRef(getCurrentInstance().proxy);
    });
    const validateNow = () => {
      state.isValid = true;
      if (props.isRequired) {
        if (state.innerValue === '') {
          state.isValid = false;
          state.errorMessage = props.isRequiredErrorMessage;
        }
      }
      if (state.isValid && props.type !== null) {
        if (props.type === 'phone') {
          if (Number.isNaN(Number(state.innerValue))) {
            state.isValid = false;
            state.errorMessage = props.typeErrorMessage;
          }
        } else if (props.type === 'email') {
          const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;          if (!re.test(String(state.innerValue).toLowerCase())) {
            state.isValid = false;
            state.errorMessage = props.typeErrorMessage;
          }
        }
      }
      if (state.isValid && props.minLength !== null) {
        if (state.innerValue.length < props.minLength) {
          state.isValid = false;
          state.errorMessage = props.minLengthErrorMessage;
        }
      }
      if (state.isValid && props.maxLength !== null) {
        if (state.innerValue.length > props.maxLength) {
          state.isValid = false;
          state.errorMessage = props.maxLengthErrorMessage;
        }
      }
    };
    const onKeyPressHandle = () => {
      if (state.isFocusBefore) {
        validateNow();
      }
    };
    const onInputHandle = (e) => {
      state.innerValue = e.target.value;
      if (state.isFocusBefore) {
        validateNow();
      }
      emit('input', {
        value: state.innerValue,
        isValid: state.isValid,
      });
    };
    const onFocusOut = (e) => {
      if(props.type === 'tags') {
        onSubmitTag(e);
      }
      state.isFocusBefore = true;
      if (state.isFocusBefore) {
        validateNow();
      }
    };
    const onkeydown = (e) => {
      emit('keydown', e);
    };
    const openSelectMenu = (e) => {
      state.xMousePosition = e.target.getBoundingClientRect().left;
      state.yMousePosition = e.clientY;

      if (!state.isMenuLightBoxIsOpen) {
        state.isMenuLightBoxIsOpen = true;
      }
    };


    const getSelectMenuBoxStyle = () => {
      const screenWidth = window.innerWidth;
      if(screenWidth <= 640) {
        return {
          bottom: '75px',
          left: (state.xMousePosition / 2) + 'px',
          width: state.mainContainerWidth + 'px'
        };
      }
      return {
        left: state.xMousePosition + 'px',
        top: state.yMousePosition + 'px',
        width: state.mainContainerWidth + 'px'
      };
    }

    const closeSelectMenu = () => {
      state.isMenuLightBoxIsOpen = false;
    };
    const selectMenuItem = (item) => {
      onFocusOut();
      state.innerValue = item.value;
      emit('input', { value: item.value, isValid:true });
      validateNow();
      closeSelectMenu();
    };

    const selectMultiSelectItem = (item) => {
      const oldValuesAsArray = state.innerValue.split(',');
      let isFound = false;
      let indexedAt = -1;
      oldValuesAsArray.forEach((value, index) => {
        if(value == item.value) {
          indexedAt = index;
          isFound = true;
        }
      });
      if(isFound) {
        oldValuesAsArray.splice(indexedAt, 1);
      } else {
        oldValuesAsArray.push(item.value);
      }
      state.innerValue = oldValuesAsArray.join(',');
      emit('input', { value: state.innerValue, isValid:true });
      validateNow();
    };

    const getMultiSelectItemCelStyle = (item) => {
      const _style = {};
      if(checkIfItemListSelected(item)) {
        _style.fontWeight = 'bold;'
      }
      return _style;
    }

    const checkIfItemListSelected = (item) => {
      const oldValuesAsArray = state.innerValue.split(',');
      return oldValuesAsArray.includes(item.value.toString());
    }

    const getSelectMenuInterfaceText = () => {
      let interfaceText = props.placeholder;
      props.options.forEach((item) => {
        if (item.value === state.innerValue) {
          interfaceText = item.text;
        }
      });
      return interfaceText;
    };
    const dialogKeyListener = (e) => {
      if (e.keyCode === 27) {
        if (state.dialogsStack[state.dialogsStack.length - 1].onClose !== undefined) {
          state.dialogsStack[state.dialogsStack.length - 1].onClose();
        }
        document.removeEventListener('keydown', dialogKeyListener);
      }
    };

    watch(() => state.isMenuLightBoxIsOpen, () => {
      if (state.isMenuLightBoxIsOpen) {
        document.addEventListener('keydown', dialogKeyListener);
      } else {
        document.removeEventListener('keydown', dialogKeyListener);
      }
    });

    const getInputSectionStyle = () => {
      const _style = {};
      if(props.type !== "toggle") {
        _style.border = '1px solid #ccc';
        _style.background = '#fcfcfc';
      }
      return _style;
    };

    const changeToggle = () => {
      if(state.innerValue === true) {
        state.innerValue = false;
      } else {
        state.innerValue = true;
      }
      emit('input', { value: state.innerValue, isValid:true });
    }

    const getToggleButtonStyle = () => {
      const _style = {
        "align-self": 'start',
        "justify-self": 'start',
        'background': 'gray',
        'place-self': 'start'
      };
      if(state.innerValue === true) {
        _style["align-self"] = 'end';
        _style["justify-self"] = 'end';
        _style["background"] = 'green';
        _style["place-self"] = 'end';
      }
      return _style;
    }

    const getTextFildType = () => {
      if(props.type === "password") {
        return 'password';
      }
      return 'text';
    }


    const focusOnTagsInput = () => {
      inputRef.value.focus();
    }

    const onSubmitTag = (e) => {
      const val = e.target.value;
      if(val !== '') {
        state.innerValue = state.innerValue + "," + val;

      }
      state.newTagValue = '';
      emit('input', {
        value: state.innerValue,
        isValid: state.isValid,
      });
    }
    const getTagsAsArray = () => {
      let arr = state.innerValue.split(",");
      arr = arr.filter(item => item);
      return arr;
    }

    const removeTag = (index) => {
      let arr = state.innerValue.split(",");
      arr = arr.filter(item => item);
      arr.splice(index, 1);
      state.innerValue = arr.join(",");
    }

    return {
      onSubmitTag,
      removeTag,
      getTagsAsArray,
      focusOnTagsInput,
      changeToggle,
      checkIfItemListSelected,
      getToggleButtonStyle,
      getInputSectionStyle,
      inputRef,
      selectMenuItem,
      onFocusOut,
      onInputHandle,
      onKeyPressHandle,
      validateNow,
      onkeydown,
      getTextFildType,
      state,
      mainContainer,
      openSelectMenu,
      getSelectMenuBoxStyle,
      closeSelectMenu,
      getSelectMenuInterfaceText,
      selectMultiSelectItem,
      getMultiSelectItemCelStyle,
    };
  },
  props: {
    options: {
      default: {},
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {},
    labelColor: {
      type: String,
      default: '#555',
    },
    type: {
      type: String,
      default: 'text',
    },
    typeErrorMessage: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    isRequiredErrorMessage: {
      type: String,
      default: '',
    },
    minLength: {
      type: Number,
      default: null,
    },
    minLengthErrorMessage: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    maxLengthErrorMessage: {
      type: String,
      default: '',
    },
    getRef: {
      type: Function,
      default: ()=>{},
    },
  },
};
</script>

<style scoped>
.DuInputMainContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
}
.DuInputMainContainer .label{
  font-size: 17px;
  /* color: #555; */
  margin-bottom: 10px;
}
.DuInputMainContainer .inputSection{
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.DuInputMainContainer input,.selectMenu, textarea{
  font-size: 17px;
  border: 0;
  padding: 14px;
  background: transparent;
  flex: 1;
}
.errorBorder{
  border: 1px solid rgba(238, 119, 154, 1) !important;
  background: #FFEFED !important;
}
.errorHandler{
  padding: 10px 2%;
  color: rgba(238, 119, 154, 1);
  border-radius: 8px;
  font-size: 14px;
}
.errorSpot{
  width: 20px;
  height: 20px;
  padding: 14px;
}
.selectMenuMark{
  align-self: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.selectMenuMark img{
  width: 20px;
  height: 20px;
  margin-top: 5px;
}
.duInputLightBox{
  width: 100%;
  height: 100%;
  left:0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 10;
}
.selectMenu{
  cursor: pointer;
}
.selectMenuBody{
  position: absolute;
  margin: -15px;
  max-height: 240px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px #000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 20;
  -webkit-transform:translate3d(0,0,0);
}
.selectMenuBody .theLightBoxTitle{
  border-bottom: 1px solid #eee;
  padding: 25px;
  font-size: 14px;
}
.selectMenuBody .theContentArea{
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}
.selectMenuBody .theContentArea .cell{
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 20px;
}
.selectMenuBody .theContentArea .cell:hover{
  background: #eee;
}
.selectMenuBody .theContentArea .theText{
  font-size: 16px;
  flex: 1;
}
.selectMenuBody .theContentArea .cell img{
  width: 16px;
  height: 16px;
}
.selectMenuPlaceHolder{ flex: 1; }

.toggleButtonHolder {
  display: flex;
  flex-direction: row;
}
.switcherPadding{
  flex: 1;
}
.toggleButton{
  border: 1px solid #999;
  height: 30px;
  width: 60px;
  border-radius: 100px;
  overflow: hidden;
  cursor: pointer;
  margin: 7px 0;
  align-self: end;
  justify-self: end;
  place-self: center;
  display: flex;
  flex-direction: column;
}

.toggleButton .switcher{
  width: 26px;
  height: 26px;
  background: #00AD75;
  border-radius: 100px;
  box-shadow: 0 0 7px #ccc;
  border: 2px solid #fff;
}

.multiSelect{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.multiSelectCel {
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
  width: available;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.multiSelectCel:hover{
  opacity: 0.8;
}
.multiSelectCel:active{
  opacity: 0.5;
}
.multiSelectCel .titleOfItem{
  flex: 1;
  font-size: 15px;
  color: #222;
}
.multiSelectCel .checkBox{
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  place-items: center;
  place-content: center;
  display: flex;
}

.tagsHolder {
  flex: 1;
  display: inline-block;
  overflow-x: hidden;
}

.tagsHolder input:focus {outline:0;}

.tagCell{
  display: inline-flex;
  flex-direction: row;
  padding-top: 5px;
  padding-inline-start: 5px;
  padding-inline-end: 15px;
  padding-bottom: 5px;
  border-radius: 100px;
  background: #000;
  width: min-content;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  gap: 10px;
  margin: 10px;
}
.tagCell .removeButton{
  padding: 3px;
  background: red;
  cursor: pointer;
  border-radius: 100px;
}

</style>