<template>

  <div class="addOrEditApplyLightBox" :style="getMainStyle()">
    <div class="mainFormContainer">
      <div class="closeButton" @click="close()">
        <IconsWorker icon="closeF" :width="30"/>
      </div>
      <div class="loadingData" v-if="state.isLoadingCats">
        <IconsWorker icon="loading" width="25"/>
      </div>
      <div v-else>
        <div class="form" v-if="!state.isApplyDone">
          <DuInput
              label="الاسم"
              name="name"
              type="text"
              type-error-message="الاسم غير صالح"
              :is-required="true"
              is-required-error-message="قم بكتابة اسمك"
              :get-ref="(e) => createFormRef(e)"
          />
          <DuInput
              label="اسم المكان"
              name="place_name"
              type="text"
              type-error-message="الاسم المكان غير صالح"
              :is-required="true"
              is-required-error-message="قم بكتابة اسم المكان"
              :get-ref="(e) => createFormRef(e)"
          />
          <DuInput
              label="البريد الإلكتروني"
              name="email"
              type="email"
              type-error-message="البريد الإلكتروني غير صالح"
              :is-required="true"
              is-required-error-message="قم بكاتبة البريد الالكتروني"
              :get-ref="(e) => createFormRef(e)"
          />
          <DuInput
              label="حساب الإنستجرام (اسم المستخدم)"
              name="instagram"
              type="text"
              type-error-message="اسم الحساب غير صالح"
              :is-required="true"
              is-required-error-message="قم بكتابة اسم مستخدم الحساب"
              :get-ref="(e) => createFormRef(e)"
          />
          <DuInput
              label="المنطقة"
              name="area"
              type="selectMenu"
              placeholder="الرجاء اختيار المدينة"
              type-error-message=""
              :is-required="true"
              is-required-error-message="الرجاء اختيار المنطقة"
              :get-ref="(e) => createFormRef(e)"
              :options="getAreasListToRender()"
          />
          <DuInput
              label="القسم"
              name="cat"
              type="selectMenu"
              placeholder="الرجاء اختيار القسم"
              type-error-message=""
              :is-required="true"
              is-required-error-message="الرجاء اختيار القسم"
              :get-ref="(e) => createFormRef(e)"
              :options="getCatsListToRender()"
          />
          <DuInput
              label="الكلمات الدلالية"
              name="tags"
              type="tags"
              placeholder="اكتب هنا"
              type-error-message=""
              :is-required="true"
              is-required-error-message="الرجاء كتابة الوسوم"
              :get-ref="(e) => createFormRef(e)"
              :options="getCatsListToRender()"
          />

          <DuInput
              label="رابط المكان على خرائط قوقل"
              name="google_map_url"
              placeholder="مثال: https://maps.google.com/xxx"
              type="text"
              type-error-message="الرابط غير صالح"
              :is-required="true"
              is-required-error-message="الرجاء كتابة الرابط"
              :get-ref="(e) => createFormRef(e)"
          />
          <DuInput
              label="هل يتطلب زيارة المكان أي تصاريح ؟"
              type="selectMenu"
              name="is_need_permission"
              :value="false"
              placeholder="التصريح"
              type-error-message=""
              is-required-error-message="رجاء قم بختيار التصريح"
              :get-ref="(e) => createFormRef(e)"
              :options="[
                    { text: 'لا', value: false, },
                    { text: 'نعم', value: true, },
                ]"
          />

          <DuInput
              label="الوصف"
              name="discreption"
              type="textarea"
              type-error-message="وصف المكان غير صالح"
              :is-required="true"
              is-required-error-message="قم بكتابة وصف للمكان"
              :get-ref="(e) => createFormRef(e)"
              :max-length="280"
              max-length-error-message="هذ الوصف طويل بعض الشيء"
          />

          <DuInputUploader
              label="صور المكان"
              place-holder="انقر هنا لإختيار صورة"
              isRequiredMessage="قم بإختيار الصور الخاصة بالمكان"
              :filesChanged="(e) => { uploadChange(e) }"
              :get-ref="(e) => state.photosRef = e"
              :init-files="state.initFiles"
          />


          <DuInput
              label="حالة الطلب"
              v-if="initData !== null"
              type="selectMenu"
              name="is_eccept"
              :value="false"
              placeholder="اختر الحالة"
              type-error-message=""
              is-required-error-message="رجاء قم بختيار التصريح"
              :get-ref="(e) => createFormRef(e)"
              :options="[
                    { text: 'غير مقبول', value: false, },
                    { text: 'مقبول', value: true, },
                ]"
          />


          <DuButton text="ارسال" class="sendButton" @click="save()"/>
        </div>
        <div class="sucessMessage" v-else>
          <IconsWorker icon="done" width="100" color="green"/>
          <p v-if="!state.isUpdate">لقد استقبلنا طلبك , وسيتم مراجعته و إدراجع في اسرع وقت , شًكرًا لك</p>
          <p v-else>تم تحديث المكان , قم بإعادة تحميل الصفحة</p>
          <DuButton text="إغلاق" class="goToHome" @click="goToHome()" background="green"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onBeforeMount, onMounted, onUnmounted, reactive, ref, watch} from 'vue';
import DuInput from "../components/DuInput";
import DuInputUploader from "../components/DuInputUploader";
import DuButton from "../components/DuButton";
import AjaxWorker from "../helpers/AjaxWorker";
import IconsWorker from "../components/IconsWorker";
import router from "../router";

export default {
  name: "AddOrEditApplyLightBox",
  components: {IconsWorker, DuButton, DuInputUploader, DuInput},
  props: {
    followPagePosition: {
      type: Boolean,
      default: true,
    },
    initData:{
      type: Object,
      default: null,
    },
    onDone:{
      type: Function,
      default: () => {},
    },
    onCancel:{
      type: Function,
      default: () => {},
    }
  },
  setup(props) {
    const state = reactive({
      formRefs: {},
      photos: [],
      photosRef: null,
      isApplyDone: false,
      isLoading: false,
      action: 'addNewItem',
      isUpdate: false,
      isLoadingCats: true,
      listOfCats: [],
      initFiles: [],
      pageTopPosition: 0,
    });
    onMounted(() => {
      getCats();
    });

    const getCats = () => {
      new AjaxWorker().request('/transactions.php?action=getCats&getAll=true').then((res) => {
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          setTimeout(() => {
            getCats();
          },2000);
        } else {


          state.isLoadingCats = false;

          state.listOfCats = res.data;
          if(props.initData !== null) {
            state.action = 'updateItem&id=' + props.initData.id;
            state.isUpdate = true;
            setTimeout(() => {
              Object.keys(state.formRefs).forEach((key) => {
                const el = state.formRefs[key];
                const name = el.state.name;
                el.state.innerValue = props.initData[name];
              });
              state.photos = props.initData["photos"].split(",");

              const _files = [];
              state.photos.forEach((fileSerial) => {
                _files.push(fileSerial);
              });
              state.initFiles = _files;

            }, 900);
          }
        }
      });
    }

    const save = () => {
      let isFormValid = true;
      const _params = {};
      Object.keys(state.formRefs).forEach((key) => {
        const el = state.formRefs[key];
        el.validateNow();
        if(!el.state.isValid) {
          isFormValid = false;
        }
        _params[el.state.name] = el.state.innerValue;
      });
      state.photosRef.validateNow();
      if(isFormValid) isFormValid = state.photosRef.state.isValid;

      if(isFormValid) {
        state.isLoading = true;
        _params.photos = state.photos.join(",");

        new AjaxWorker().request('/transactions.php?action=' + state.action, _params).then((response) => {
          state.isLoading = false;
          state.isApplyDone = true;
        });
      }
    }
    const uploadChange = (files) => {
      state.photos = [];
      files.value.forEach((file) => {
        if(file.state === "uploaded") {
          state.photos.push(file.serial);
        }
      });
    }
    const goToHome = () => {
      state.isApplyDone = false;
      props.onDone();
    }
    const close = () => {
      props.onCancel()
    }
    const createFormRef = (ref) => state.formRefs[ref.state.name] = ref;


    const getCatsListToRender = () => {
      const _list = [];
      state.listOfCats.forEach((item) => {
        if(item.type === 'cat') {
          _list.push({
            text: item.title,
            value: item.id,
          });
        }
      });
      return _list;
    }
    const getAreasListToRender = () => {
      const _list = [];
      state.listOfCats.forEach((item) => {
        if(item.type === 'area') {
          _list.push({
            text: item.title,
            value: item.id,
          });
        }
      });
      return _list;
    }

    const getMainStyle = () => {
      const _style = {};
      if(props.followPagePosition) {
        _style.marginTop = state.pageTopPosition + 'px';
      }
      return _style;
    }

    onMounted(() => {
      if(props.followPagePosition) {
        const doc = document.getElementsByTagName("body")[0];
        const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        state.pageTopPosition = top;
        document.body.style.overflow = 'hidden';
      }
    });
    onUnmounted(() => {
      if(props.followPagePosition) {
        document.body.style.overflow = 'auto';
      }
    });

    return {
      getMainStyle,
      getCatsListToRender,
      getAreasListToRender,
      close,
      createFormRef,
      uploadChange,
      goToHome,
      save,
      state,
    };
  }
}

</script>

<style scoped>

.addOrEditApplyLightBox{
  position: fixed;
  z-index: 110;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left:0;
  top:0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}

.mainFormContainer {
  width: 35%;
  padding: 50px 2.5%;
  margin: 100px 30%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  text-align: start;
  position: absolute;
  min-height: 100px;
  overflow: hidden;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.sendButton{
  align-self: end;
  margin-top: 10px;
}
.sucessMessage{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
}
.sucessMessage p{
  margin-top: 20px;
  font-size: 18px;
  text-align: center;
}
.goToHome{
  place-self: center;
  align-self: center;
  justify-self: center;
  margin-top: 50px;
}

.closeButton{
  place-self: end;
  padding: 5px;
  background: #ccc;
  border-radius: 100px;
  margin-bottom: 10px;
  cursor: pointer;
}
.closeButton:hover{ opacity:  0.8; }
.closeButton:active{ opacity:  0.5; }
.loadingData{
  place-self: center;
  display: flex;
  place-items: center;
  place-content: center;
}

@media only screen and (max-width: 600px) {
  .mainFormContainer{
    width: 80%;
    margin: 100px 5%;
    padding: 20px 5%;
  }
}
</style>