<template>
  <div class="menu" ref="menuRef">
    <div class="fixedLogo" v-if="showLogo"></div>
    <router-link :class="getClasses(item, index)" :id="'navCel' + index" v-for="(item, index) in state.items" :key="item.key" :to="item.stopEvent === undefined ? item.link : '#'" @click="makeAnAction(item)">
      {{item.text}}
    </router-link>
    <AddOrEditApplyLightBox v-if="state.showAddDialog" :onDone="() => onAddOrEditDone()" :onCancel="() => onCancel()" :followPagePosition="false"/>
  </div>
</template>

<script>
import {onMounted, reactive, ref, watch} from 'vue'
import AddOrEditApplyLightBox from "../views/AddOrEditApplyLightBox";
import router from "../router";
import {useRoute} from "vue-router";
export default {
  name: "TopMenu",
  components: {AddOrEditApplyLightBox: AddOrEditApplyLightBox},
  props: {
    showLogo: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      items: [
        { link: '/', text: 'الرئيسية' , key: 'home' },
        { link: '/addNewPlace', text: 'اضف مكان' , key: 'addNewPlace' },
        { link: '/areas', text: 'المناطق' , key: 'areas' },
        { link: '/categories', text: 'التصنيفات' , key: 'cats' },
        { link: '/aboutUs', text: 'من نحن' , key: 'aboutUs' },
        { link: '/contactUs', text: 'تواصل معنا' , key: 'contactUs' },
      ],
      selected: '/',
      selectedIndex: 0,
      showAddDialog: false,
    });

    const menuRef = ref();

    const router = useRoute();
    watch(() => router.path, () => {
      state.selected = router.path;
    });
    onMounted(() => {
      menuRef.value.scrollTo(-100, -100);
      setTimeout(() => {

        const movingOffsets = document.getElementById('navCel' + state.selectedIndex).offsetLeft
            - menuRef.value.offsetWidth/2 + document.getElementById('navCel' + state.selectedIndex).offsetWidth/2;
        menuRef.value.scrollTo(movingOffsets, movingOffsets);
      }, 500);
      state.selected = router.path;
    });
    const getClasses = (item, index) => {
      let theClass = 'cell';
      if(state.selected === item.link) {
        state.selectedIndex = index;
        theClass = theClass + ' selected';
      }
      return theClass;
    }
    const makeAnAction = (item) => {
      if(item.key === "addNewPlace") {
        state.showAddDialog = true;
      }
    }
    const onAddOrEditDone = () => {
      state.showAddDialog = false;
    }
    const onCancel = () => {
      state.showAddDialog = false;
    }
    return {
      state,
      onAddOrEditDone,
      menuRef,
      getClasses,
      onCancel,
      makeAnAction,
    };
  }
}
</script>

<style scoped>
  .menu{
    margin: 50px 0px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    height: 30px;
    position: relative;
  }

  .menu .cell{
    padding: 5px 00px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-underline-style: none;
  }
  .menu .selected{
    padding: 3px 15px;
    border-radius: 100px;
    border: 1px solid #fff;
  }

  .fixedLogo{
    width: 150px;
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    margin: -70px 0%;
    background-image: url("../assets/images/logo.png");
    background-size: auto 100%;
    background-position: center;
    z-index: 0;
  }

  @media only screen and (max-width: 600px) {
    .menu{
      gap: 20px;
      flex-wrap: wrap;
      flex-direction: column;
      overflow-y: auto;
      scroll-behavior: smooth;
    }
    .menu .cell{
      font-size: 12px;
      width: max-content;
      height: 25px;
    }
    .menu .selected {
      padding: 2px 10px;
    }
    .fixedLogo{
      display: none;
    }
  }
</style>