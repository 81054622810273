<template>
  <div class="mainHeader">
    <div class="mainContainer">
      <div class="mainArea">
        <div class="userInfoAndThumb" @click="(e) => onClickThumbAndUserInfo(e)" v-if="isLogin()">
          <div class="thumb" :style="getThumb()"></div>
          <div class="nameAndUsername">
            <div class="name">{{ getMyData().name }}</div>
            <div class="username">{{ getMyData().username }}</div>
          </div>
        </div>
        <div class="fillRemain"></div>
        <img src="../assets/images/logo.png" class="headerLogo" @click="onLogoClick()"/>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import DuContextMenu from "./plugins/duContextMenu/duContextMenu";
import myAccountAsyncStore from "../store/myAccountAsyncStore";
import router from "../router";
import FixedNotification from "./plugins/fixedNotifications/FixedNotification";
import AjaxWorker from "../helpers/AjaxWorker";

export default {
  name: "Header",
  setup() {
    const state = reactive({

    });
    const getThumb = () => {
      const _style = {
        backgroundImage: `url('${require('@/assets/images/defaultUserThumb.png')}')`,
        'background-position': 'center',
      };
      if(myAccountAsyncStore.getters.getData.thumb !== '') {
        const _thumb = new AjaxWorker().url + "/media/" + myAccountAsyncStore.getters.getData.thumb + "?w=80";
        _style.backgroundImage = `url('${_thumb}')`;
      }
      return _style;
    }
    const onClickThumbAndUserInfo = (e) => {
      const _options = [];

      if(getMyData().rank === 3) {
        _options.push({ title: 'لوحة التحكم', key: 'goToControlPanel', icon: 'wrenchF' });
      }

      _options.push(...[
        { title: 'إعدادات الحساب', key: 'goToAccountSettings', icon: 'settingF' },
        { title: 'تسجيل الخروج', key: 'logout', icon: 'logoutF' },
      ]);
      DuContextMenu.create({
        event: e,
        onSelect: async (key) => {
          if(key === "goToAccountSettings"){
            await router.push('/myAccount');
          } else if(key === "goToControlPanel"){
            await router.push('/controlPanel');
          }else if(key === "logout"){
            FixedNotification.create({
              decription: "تم تسجيل خروجك",
              icon: "roundLockF",
              color: 'green',
              hideAfter: 3000
            });
            await myAccountAsyncStore.dispatch("logout");
            await router.replace('/');
          }
        },
        options: _options,
      });
    }
    const getMyData = () => {
      return myAccountAsyncStore.getters.getData;
    }
    const onLogoClick = () => {
      router.push('/');
    }
    const isLogin = () => myAccountAsyncStore.getters.isLogin;

    return {
      getThumb,
      isLogin,
      getMyData,
      onClickThumbAndUserInfo,
      onLogoClick,
    }
  }
}
</script>

<style scoped>
  .mainHeader{
    display: flex;
    position: fixed;
    background: #220044;
    padding: 10px 0;
    width: 100%;
    z-index: 50;
  }
  .mainArea{
    display: flex;
    flex-direction: row;
    height: 40px;
  }
  .headerLogo{
    height: 100%;
    cursor: pointer;
  }

  .mainHeader .fillRemain{
    flex: 1;
  }
  .mainHeader .userInfoAndThumb{
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .mainHeader .userInfoAndThumb .thumb{
    height: 40px;
    width: 40px;
    background-color: #fff;
    border-radius: 100px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: cover;
  }
  .mainHeader .nameAndUsername{
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: start;
  }
  .mainHeader .nameAndUsername .name{
    font-size: 15px;
  }
  .mainHeader .nameAndUsername .username{
    font-size: 13px;
  }

  @media only screen and (max-width: 600px) {
    .mainHeader .nameAndUsername .name{
      font-size: 13px;
    }
    .mainHeader .nameAndUsername .username{
      font-size: 11px;
    }
  }

</style>