import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import AddNewPlace from "../views/AddNewPlace";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/addNewPlace',
    name: 'AddNewPlace',
    component: AddNewPlace
  },
  {
    path: '/login',
    name: 'Login',
    component:  () => import('../views/LoginAndJoin.vue')
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () =>  import('../views/ResetPassword.vue')
  },
  {
    path: '/aboutUs',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/placeInfo/:id',
    name: 'PlaceInfo',
    component: () => import('../views/PlaceInfo.vue'),
  },
  {
    path: '/areas',
    name: 'areas',
    component: () => import('../views/Categories.vue'),
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/Categories.vue'),
    children: [
      {
        path: 'city/:area',
        name: 'categoriesByCity',
        component: () => import('../views/Categories.vue'),
      },
    ]
  },
  {
    path: '/places',
    name: 'Places',
    component: () => import('../views/Photos.vue'),
    children: [
      {
        path: 'city/:area',
        name: 'PlacesByCity',
        component: () => import('../views/Photos.vue'),
      },
      {
        path: 'citySearch/:area/:keyword',
        name: 'PlacesByCityAndSearch',
        component: () => import('../views/Photos.vue'),
      },
      {
        path: ':cat',
        name: 'PlacesById',
        component: () => import('../views/Photos.vue'),
      },
      {
        path: 'cityCategorie/:area/:cat',
        name: 'cityCategoriePhotos',
        component: () => import('../views/Photos.vue'),
      },
    ]
  },
  {
    path: '/MyAccount',
    name: 'MyAccount',
    component: () => import('../views/MyAccount.vue'),
  },
  {
    path: '/controlPanel',
    name: 'ControlPanel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ControlPanel.vue'),
    children: [
      {
        path: 'users',
        component: () => import('../views/ControlPanel/Users.vue'),
      },
      {
        path: 'users/addNew',
        component: () => import('../views/ControlPanel/AddOrEditUser.vue'),
      },
      {
        path: 'applys',
        component: () => import('../views/ControlPanel/Applys.vue'),
      },
      {
        path: 'contactUsMessages',
        component: () => import('../views/ControlPanel/ContactUsMessages.vue'),
      },
      {
        path: 'comments',
        component: () => import('../views/ControlPanel/Comments.vue'),
      },
      {
        path: 'contactInfoSettings',
        component: () => import('../views/ControlPanel/ContactInfoSettings.vue'),
      },
      {
        path: 'classefications/:type',
        component: () => import('../views/ControlPanel/Cats.vue'),
      },
      {
        path: 'addNewClassification',
        component: () => import('../views/ControlPanel/AddNewCat'),
      },
      {
        path: 'editCat/:id',
        component: () => import('../views/ControlPanel/AddNewCat'),
      },
      {
        path: 'fixedPages',
        component: () => import('../views/ControlPanel/FixedPages'),
      },
      {
        path: 'editFixedPage/:page',
        component: () => import('../views/ControlPanel/EditFixedPage'),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
