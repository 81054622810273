<template>
  <div class="mainFooterContainer">
    <img src="../assets/images/footerBar.jpg" class="footerBar"/>
  <div class="footer">
    <div class="section">
      <div class="sectionTitle">تابعنا</div>
      <div class="socialIcons">
        <a :href="item.link" v-for="item in state.socailMediaInfo" :key="'social_' + item.link" target="_blank">
          <IconsWorker :icon="item.icon" color="#fff" width="20"/>
        </a>
      </div>
    </div>
    <div class="section">
      <div class="sectionTitle">روابط مهمة</div>
      <div class="importantLinks" :href="item.link" v-for="item in importantLinks" :key="item.link">
        <router-link class="importantLink" :to="item.link" v-if="item.link !== '#'">
          <IconsWorker width="10" icon="back" color="#fff"/>
          <span>{{item.text}}</span>
        </router-link>
        <div class="importantLink" @click="openAddDialog()" v-else>
          <IconsWorker width="10" icon="back" color="#fff"/>
          <span>{{item.text}}</span>
        </div>
      </div>
    </div>
    <a href="https://dpmp.sa" class="section">
      <img src="../assets/images/byLogo.png" class="byLogo"/>
    </a>
  </div>
  </div>
  <AddOrEditApplyLightBox v-if="state.showAddDialog" :onDone="() => onAddOrEditDone()" :onCancel="() => onCancel()" :followPagePosition="false"/>

</template>

<script>
import IconsWorker from "./IconsWorker";
import {onMounted, reactive} from "vue";
import AddOrEditApplyLightBox from "../views/AddOrEditApplyLightBox";
import AjaxWorker from "../helpers/AjaxWorker";
export default {
  name: "Footer",
  components: { IconsWorker, AddOrEditApplyLightBox},
  setup() {
    const state = reactive({
      showAddDialog: false,
      socailMediaInfo: [
        { key: 'supportInstagramLink', link: '', icon: 'instagramF', },
        { key: 'supportFacebookLink', link: '', icon: 'facebookF', },
        { key: 'supportTwitterLink', link: '', icon: 'twitterF', },
        { key: 'supportLinkedInLink', link: '', icon: 'linkedinF', },
        { key: 'supportSnapChatLink', link: '', icon: 'snapchatF', },
        { key: 'supportWhatsAppLink', link: '', icon: 'whatsappF', },
        { key: 'supportYoutubeLink', link: '', icon: 'youtubeF', },
        { key: 'supportTelegramLink', link: '', icon: 'telegramF', },
      ]
    });
    onMounted(() => {
      getSocailMediaLinks();
    });
    const importantLinks = [
      { link: '/', text: 'الرئيسية', },
      { link: '/aboutUs', text: 'عنا', },
      { link: '/addNewPlace', text: 'إضافة مكان', },
    ];
    const onAddOrEditDone = () => {
      state.showAddDialog = false;
    }
    const onCancel = () => {
      state.showAddDialog = false;
    }
    const openAddDialog = () => {
      state.showAddDialog = true;
    }
    const getSocailMediaLinks = () => {
      const keys = [];
      state.socailMediaInfo.forEach((el) => {
        keys.push(el.key);
      });
      state.isLoading = true;
      new AjaxWorker().request("/transactions.php?action=getSettings&keys=" + keys.join(',')).then((res) => {
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          setTimeout(() => {
            getSocailMediaLinks();
          }, 3000);
        } else {
          console.log(res);
          state.socailMediaInfo.forEach((el, index) => {
            state.socailMediaInfo[index].link = res.data[el.key];
          });
          state.isLoading = false;
        }
      });
    }

    return {
      openAddDialog,
      state,
      onAddOrEditDone,
      onCancel,
      importantLinks,
    }
  }
}
</script>

<style scoped>

.mainFooterContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.footerBar{
  width: 100%;
  height: 5px;
}
  .footer{
    width: 80%;
    padding: 50px 10%;
    background-color: #222;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .section{
    color: #fff;
    justify-content: start;
    justify-items: start;
    text-align: start;
  }
  .sectionTitle{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .socialIcons{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  .socialIcons a{
    flex-basis: 10.666%
  }
  .importantLinks{
    display: flex;
    flex-direction: column;
  }
  .importantLink{
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 7px;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
  }

  .byLogo{
    width: 150px;
  }

@media only screen and (max-width: 600px) {

  .footer{
    flex-direction: column;
    gap: 20px;
  }
  .section{
    width: 100%;
    border-bottom: 1px solid #666;
    padding-bottom: 20px;
  }
  .byLogo{
    width: 50px;
    place-self: end;
  }


}
</style>